import { Box, FormControl, Grid, TextField } from "@material-ui/core"
import { makeStyles } from '@material-ui/styles'
import { Fragment } from "react"
const useStyles = makeStyles(theme => ({
    formControl: {

        width: '100%',

        '& .Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f44336',
            },
        },
        '& textarea': {
            fontSize: '16px',
            fontWeight: "300",

        },
        "& textarea::placeholder": {
            fontSize: '14px',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontSize: '10px',
            margin: '5px 0 5px 0',
            color: '#f44336',
        },
        '& .Mui-focused fieldset': {
            borderWidth: "1px !important",
            borderColor: theme.palette.custom.main + " !important"
        },

    },



}))
const Message = ({ classes, onChange, data }) => {
    const Styles = useStyles()
    return (
        <Fragment>
            <Grid item   {...{ xs: 12 }} style={{
                fontSize: "14px",
                fontWeight: "500",
                display: "flex",
                justifyContent: "space-between"
            }} >

                <Box style={{
                    fontSize: "14px",
                    fontWeight: "500"
                }}>
                    ข้อความตอบกลับ <span style={{ color: 'red' }}>*</span>
                </Box>
                <Box style={{
                    // color: "#e3e3e3",
                    fontSize: "12px",
                    color: (data?.message || "").length == 255 ? "#f16666ba" : "#e3e3e3",
                }}>
                    {`${(data?.message || "").length}/255`}
                </Box>
            </Grid>
            <Grid item   {...{ xs: 12 }} >
                <FormControl variant="outlined" className={Styles.formControl}>
                    <TextField variant="outlined"
                        multiline
                        minRows={3}
                        id="message"
                        name="message"
                        disabled={false}
                        type={'text'}
                        onChange={(props) => onChange(props.target)}
                        value={data?.message}
                        inputProps={{
                            maxLength: 255,
                        }}
                        placeholder="ระบุข้อความตอบกลับ"
                        error={data?.error?.message}
                        helperText={data?.error?.message}
                    />
                </FormControl>
            </Grid>
        </Fragment>

    )
}

export default Message