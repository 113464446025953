import { makeStyles } from '@material-ui/styles'
const useStyles = makeStyles(theme => ({
    formControl: {
        height: '48px',
        width: '100%',
        '& .MuiInputBase-formControl': {
            height: '48px',
        },
        '& .MuiSelect-select': {
            padding: '0 10px',
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(10px, 5px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -5px) scale(.5)',
        },
        '& .Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f44336',
            },
        },
        '& .MuiFormHelperText-root': {
            fontSize: '10px',
            margin: '0',
            color: 'transparent',
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: '#f44336',
        },
        '& .Mui-focused fieldset': {
            borderWidth: "1px !important",
            borderColor: theme.palette.custom.main + " !important"
        },
        '& input': {
            fontSize: '14px',
            height: '48px',
            padding: '0px 10px',
            color: "#000",
            fontWeight: "300"
        },
        '& fieldset': { borderColor: '#e3e3e3 !important' },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .Mui-disabled.MuiInputBase-formControl': {
            background: '#f9f9f9',
            color: "#c5c5c5"
        },
        '& .FileUploader': {
            '& .dropfile': {
                width: '150px',
                height: '100px',
                minWidth: '150px',
                border: '1px solid ' + theme.palette.custom.main + ' !important',
                borderRadius: '10px',
                padding: '10px',
                cursor: 'pointer',
            },
            '& .dropfile.error': {
                border: '1px solid red !important',
                '& .dropfile-body': {
                    border: '1px dashed red !important',
                },
            },
            '& .dropfile-body': {
                border: '1px dashed  ' + theme.palette.custom.main + ' ',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
            },
            '& .dropfile-body.image': {
                border: 'none',
            },
        },
        "& .MuiSelect-root.MuiSelect-select": {
            fontSize: "14px",
            fontWeight: "300"
        }
    },
    lbl: {
        // display: 'flex',
        alignItems: 'center',
        // padding: "0 10px",
        width: 'calc(100% - 400px)',
        minWidth: '130px',
        maxWidth: '130px',
    },
    backButton: {
        border: '1px solid ' + theme.palette.custom.main,
        backgroundColor: '#FFF',
        '& .MuiButton-label': {
            color: theme.palette.custom.main,
        },
        '&:hover': {
            border: '1px solid ' + theme.palette.custom.main,
            backgroundColor: '#FFF',
        },
        boxShadow: "none"
    },


}))

export default useStyles