import React from "react";

import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import Resetpassword from "./Auth/resetpassword";

import carousel from "./Pages/carousel";
import carouselInfo from "./Pages/carousel/info";
import broadcast from "./Pages/broadcast";
import profile from "./Pages/profile";

import airAuality from "./Pages/quickReply/airAuality/list";
import airAualityInfo from "./Pages/quickReply/airAuality/info";

import safetyZone from "./Pages/quickReply/safetyZone/list";
import safetyZoneInfo from "./Pages/quickReply/safetyZone/info";

import forecast from "./Pages/quickReply/forecast/list";
import forecastInfo from "./Pages/quickReply/forecast/info";

import hotspot from "./Pages/quickReply/hotspot/list";
import hotspotInfo from "./Pages/quickReply/hotspot/info";

import { setAuthUser } from "../redux/actions/Auth";
import { getAuthUser } from "../services/http";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const dispatch = useDispatch();

  let { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  if (authUser === null) {
    if (getAuthUser() !== null) {
      dispatch(setAuthUser(getAuthUser()));
    }

    if (location.pathname === "" || location.pathname === "/") {
      return <Redirect to={"/broadcast"} />;
    } else if (authUser && location.pathname === "/signin") {
      return <Redirect to={"/broadcast"} />;
    }
    // console.clear()
  } else {
    if (location.pathname === "" || location.pathname === "/") {
      return <Redirect to={"/broadcast"} />;
    } else if (authUser && location.pathname === "/signin") {
      return <Redirect to={"/broadcast"} />;
    }
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/carousel" component={carousel} />
        <RestrictedRoute path="/carouselInfo/:Id?" component={carouselInfo} />
        <RestrictedRoute path="/broadcast" component={broadcast} />
        <RestrictedRoute path="/profile" component={profile} />

        <RestrictedRoute path="/air-quality" component={airAuality} />
        <RestrictedRoute path="/safety-zone" component={safetyZone} />
        <RestrictedRoute path="/forecast" component={forecast} />
        <RestrictedRoute path="/hotspot" component={hotspot} />

        <RestrictedRoute
          path="/air-quality-info/:Id?"
          component={airAualityInfo}
        />
        <RestrictedRoute
          path="/safety-zone-info/:Id?"
          component={safetyZoneInfo}
        />
        <RestrictedRoute path="/forecast-info/:Id?" component={forecastInfo} />
        <RestrictedRoute path="/hotspot-info/:Id?" component={hotspotInfo} />

        <Route path="/signin" component={Login} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/resetpassword/:Id" component={Resetpassword} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
