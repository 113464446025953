import React, { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import service from "undefined-service-web";

import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import { Grid, Button, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { fetchStart, fetchSuccess } from "../../../redux/actions";
import AppContext from "../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";

import { FileUploader } from "react-drag-drop-files";

import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  search: {
    "& .MuiInputBase-root,input": {
      height: "30px",
      borderRadius: "20px",
      fontSize: "14px",
    },
    width: "100%",
    maxWidth: "600px",
  },
  FileUploader: {
    "& .dropfile": {
      width: "100%",
      border: "1px solid " + theme.palette.custom.main + " !important",
      borderRadius: "10px",
      cursor: "pointer",
    },
    "& .dropfile.error": {
      border: "1px solid red !important",
      "& .dropfile-body": {
        border: "1px dashed red !important",
      },
    },
    "& .dropfile-body": {
      border: "1px dashed  " + theme.palette.custom.main + " ",
      width: "100%",
      height: "100%",
      borderRadius: "10px",
    },
    "& .dropfile-body.image": {
      border: "none",
    },
  },
}));
const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setAlertDialog, setAlert } = useContext(AppContext);
  const FileUploaderRef = React.useRef(null);
  const [data, setdata] = React.useState({
    image: {
      invalid: false,
      text: "",
      msg: "",
    },
  });
  const [preview, setpreview] = useState([]);

  const broadcast = async () => {
    if (!service.isNullOrEmpty(data.image.text)) {
      dispatch(fetchStart());
      console.log("broadcast");
      let _tbbroadcast = await service.postHttp("/broadcast/send", dispatch);
      if (_tbbroadcast.status) {
        if (_tbbroadcast.data.status) {
          setAlertDialog({
            open: true,
            type: "alert",
            msg: "broadcast สำเร็จ",
            Close: (even) => {
              even();
            },
          });
        } else {
          setAlertDialog({
            open: true,
            type: "alert",
            msg: "broadcast ไม่สำเร็จ",
            Close: (even) => {
              even();
            },
          });
        }
      } else {
        setAlertDialog({
          open: true,
          type: "alert",
          msg: "broadcast ไม่สำเร็จ",
          Close: (even) => {
            even();
          },
        });
      }
      dispatch(fetchSuccess());
    } else {
      setAlertDialog({
        open: true,
        type: "alert",
        msg: "broadcast ยังไม่ได้กำหนดรูป",
        Close: (even) => {
          even();
        },
      });
    }
  };
  const gettbbroadcast = async (isCurrent) => {
    dispatch(fetchStart());
    let res = await service.getHttp("/broadcast", dispatch);
    dispatch(fetchSuccess());
    // console.log(res);
    if (res.status) {
      setdata({
        image: {
          text: process.env.REACT_APP_KEY_URLAPI + res.tbBroadcast.imagePath,
        },
      });
    }
  };
  const updatebroadcast = async (image) => {
    dispatch(fetchStart());
    await service.postHttp("/broadcast", { image: image });
    dispatch(fetchSuccess());
    gettbbroadcast(true);

    setAlert({ open: true, type: "success", msg: "บันทึกข้อมูลสำเร็จ" });
  };
  useEffect(() => {
    let isCurrent = true;
    gettbbroadcast(isCurrent);
    return () => {
      isCurrent = false;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const PM25UC = ({ name, index }) => {
    return (
      <Box style={{ display: "flex" }} mb={1.5}>
        <Box className={classes.lbl} style={{ width: "120px" }}>
          {name}
        </Box>
        <Box
          className={classes.lbl}
          style={{
            width: "100px",
            color: service.isNullOrEmpty(preview[index]?.realtime.level)
              ? "#000"
              : preview[index]?.realtime.level,
          }}
        >
          {service.isNullOrEmpty(preview[index]?.realtime.pm25)
            ? "-"
            : preview[index]?.realtime.pm25}
        </Box>
        <Box
          className={classes.lbl}
          style={{
            // width: '100px',
            color: service.isNullOrEmpty(preview[index]?.daily.level)
              ? "#000"
              : preview[index]?.daily.level,
          }}
        >
          {service.isNullOrEmpty(preview[index]?.daily.pm25)
            ? "-"
            : preview[index]?.daily.pm25}
        </Box>
      </Box>
    );
  };
  const getpreview = async () => {
    dispatch(fetchStart());
    let _preview = await service.postHttp("/broadcast/getpreview", dispatch);
    if (_preview.status) {
      if (_preview.data.status) {
        setpreview(_preview.data.preview);
      }
    }
    dispatch(fetchSuccess());
  };
  return (
    <PageContainer heading={""}>
      <Grid container xs={12} spacing={2}>
        <Grid
          item
          sm={12}
          style={{
            fontSize: "20px",
            fontWeight: "700",
            padding: 20,
          }}
        >
          <IntlMessages id="pages.manageBroadcast" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          // style={{ padding: "0 20px 0 0", marginBottom: "15px" }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              aspectRatio: 1,
            }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  // border: "1px solid red",
                  aspectRatio: 1,
                  borderRadius: 10,
                  maxWidth: "420px",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    position: "absolute",
                    zIndex: 999,
                    width: "48px",
                    aspectRatio: 1,
                    backgroundColor: "rgba(255,255,255, 0.7)",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    right: 10,
                    top: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => FileUploaderRef.current.children[0].click()}
                >
                  <EditIcon
                    sx={{
                      color: "red",
                    }}
                  />
                </Box>
                <Box ref={FileUploaderRef} className={classes.FileUploader}>
                  <FileUploader
                    multple={false}
                    handleChange={(e) => {
                      if (
                        e.type.toLowerCase().includes("png") ||
                        e.type.toLowerCase().includes("jpeg")
                      ) {
                        console.log("e", e);

                        let _URL = window.URL || window.webkitURL;
                        let img = new Image();
                        const objectUrl = _URL.createObjectURL(e);

                        img.onload = function () {
                          if (this.width !== 1024 || this.height !== 1024) {
                            setAlertDialog({
                              open: true,
                              type: "alert",
                              msg: "ขนาดรูปภาพไม่ถูกต้อง กรุณาอัปโหลดรูปภาพใหม่ (ขนาดภาพแนะนำ 1024 * 1024 px)",
                              Close: (even) => {
                                even();
                              },
                            });
                          } else {
                            service.getBase64(e, (result) => {
                              setAlertDialog({
                                open: true,
                                type: "confirm",
                                msg: "ต้องการเปลี่ยนแปลงข้อมูล ใช่หรือไม่?",
                                Close: (even) => {
                                  even();
                                },
                                Ok: (even) => {
                                  even();
                                  updatebroadcast(result);
                                },
                              });
                            });
                          }
                          _URL.revokeObjectURL(objectUrl);
                        };
                        img.src = objectUrl;
                      } else {
                        setAlertDialog({
                          open: true,
                          type: "alert",
                          msg: "ประเภทไฟล์ไม่ถูกต้อง*ไฟล์ที่รองรับ: .JPEG, .PNG",
                          Close: (even) => {
                            even();
                          },
                        });
                      }
                    }}
                    name="image"
                    types={["png", "jpeg", "jpg"]}
                  >
                    <Box
                      className={
                        "dropfile" + (data.image.invalid ? " error" : "")
                      }
                    >
                      <Box
                        className={
                          "dropfile-body " +
                          (service.isNullOrEmpty(data.image.text)
                            ? ""
                            : "image")
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        {service.isNullOrEmpty(data.image.text) ? (
                          <Box>
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button variant="contained" color="primary">
                                Choose File
                              </Button>
                            </Box>
                            <Box
                              style={{
                                fontSize: "9px",
                                textAlign: "center",
                                marginTop: "5px",
                                color: data.image.invalid ? "red" : "#000",
                              }}
                            >
                              Or drag and drop file
                            </Box>
                            {data.image.invalid ? (
                              <Box
                                style={{
                                  color: "red",
                                  fontSize: "9px",
                                  textAlign: "center",
                                }}
                              >
                                {data.image.msg}
                              </Box>
                            ) : null}
                          </Box>
                        ) : (
                          <img
                            // style={{ height: "100%", objectFit: "contain" }}
                            style={{
                              width: "100%",
                              aspectRatio: 1,
                            }}
                            src={data.image.text}
                            alt="image"
                          />
                        )}
                      </Box>
                    </Box>
                  </FileUploader>
                </Box>
              </Box>
              <Box
                style={{ marginTop: 10, textAlign: "center", color: "#9C9C9C" }}
              >
                (ขนาดภาพ 1024*1024 px)
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={broadcast}
                  style={{
                    width: 100,
                    height: 40,
                  }}
                >
                  Broadcast
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box>
            <Box style={{ display: "flex" }}>
              <Box className={classes.lbl} style={{ width: "120px" }}></Box>
              <Box className={classes.lbl} style={{ width: "100px" }}>
                Realtime
              </Box>
              <Box className={classes.lbl} style={{ width: "100px" }}>
                Daily
              </Box>
            </Box>
            <PM25UC name="เชียงใหม่" index={0} />
            <PM25UC name="น่าน" index={1} />
            <PM25UC name="ขอนแก่น" index={2} />
            <PM25UC name="อุบลราชธานี" index={3} />
            <PM25UC name="แม่ฮ่องสอน" index={4} />
            <PM25UC name="พิษณุโลก" index={5} />
            <PM25UC name="สระบุรี" index={6} />
            <PM25UC name="จันทบุรี" index={7} />
            <PM25UC name="ตาก" index={8} />
            <PM25UC name="ระยอง" index={9} />
            <PM25UC name="กาญจนบุรี" index={10} />
            <PM25UC name="กรุงเทพมหานคร" index={11} />
            <PM25UC name="สงขลา" index={12} />
            <PM25UC name="ภูเก็ต" index={13} />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={getpreview}
              style={{
                width: 100,
                height: 40,
              }}
            >
              preview
            </Button>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Index;
