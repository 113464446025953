import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages'
import { Grid, Button } from '@material-ui/core'


const Head = ({
    pangNameInfo,
    breadCrumbs
}) => {
    return (
        <Fragment>
            <Grid container item style={{
                paddingBottom: 20
            }}
                sm={6}
                spacing={2}
            >
                <Grid item sm={12} style={{
                    fontSize: "20px",
                    fontWeight: "700"
                }} >
                    {breadCrumbs?.primary}
                </Grid>
                <Grid item sm={12} style={{
                    color: "#5C5D5E",
                    fontSize: "14px",
                    fontWeight: "400"
                }}>
                    {breadCrumbs?.secondary}
                </Grid>

            </Grid>
            <Grid
                item
                xs={6}
                // sm={4}
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: "center"
                }}>
                <NavLink to={`/${pangNameInfo}`} style={{ color: '#FFF' }}>
                    <Button variant="contained" color="primary"
                        style={{
                            textAlign: "center",
                            width: 100,
                            height: 40
                        }}>
                        <IntlMessages id="appModule.add" />
                    </Button>
                </NavLink>
            </Grid>
        </Fragment>
    )
}

export default Head