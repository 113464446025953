import React, { useEffect, useContext } from "react";
import App from "components";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Box, Button } from "@material-ui/core";
import { Header, Detail, useStyles } from "../../components/info";
// import Axios from "axios"
const listPage = "forecast";
const apiPath = "/quickReply/forecast/";

const Info = () => {
  const { Id } = useParams();
  const { setAlertDialog, setAlert } = useContext(App.AppContext);
  const classes = useStyles();
  const dispatch = App.Redux.useDispatch();
  const history = useHistory();

  const [data, setData] = React.useState({
    id: Id || null,
    isModify: false,
    type: "message",
    is_display: "false",
    tb_flex_messages: [],
    error: {},
  });

  const onChange = (props) => {
    let message = data.message;
    let _error = data.error;
    if (props.name == "type") {
      message = "";
      _error.message = null;
      _error.reply_image = null;
    }
    setData((e) => {
      return {
        ...e,
        isModify: true,
        message,
        [props.name]: props.value,
        error: {
          ...e.error,
          ..._error,
          [props.name]: null,
        },
      };
    });
  };

  const getData = async (Id) => {
    dispatch(App.Redux.fetchStart());
    const res = await App.service.getHttp(
      apiPath + (Id || data.id || ""),
      dispatch
    );
    dispatch(App.Redux.fetchSuccess());
    if (res.status) {
      setData((e) => {
        return { ...e, ...res?.data };
      });
    }
  };
  const doSave = async (callback) => {
    if (await valid()) {
      let item = {};
      Object.keys(data).map((e) => {
        if (e != "error") {
          item[e] = data[e];
        }
      });
      dispatch(App.Redux.fetchStart());
      let res = await App.service.postHttp(
        App.service.isNullOrEmpty(data.id)
          ? apiPath + "create"
          : "/quickReply/update",
        item
      );
      dispatch(App.Redux.fetchSuccess());
      if (res.status) {
        if (res.data.status) {
          if (App.service.isNullOrEmpty(data.id)) {
            window.history.replaceState(
              null,
              null,
              `/${listPage}-info/${res.data.data}`
            );
          }
          setData((e) => {
            return { ...e, isModify: false, id: res.data.data };
          });
          getData(res.data.data);
          setAlert({ open: true, type: "success", msg: "บันทึกข้อมูลสำเร็จ" });
        } else {
          setAlertDialog({
            open: true,
            msg: res.data.error,
            Ok: (e) => {
              e();
            },
          });
        }
      }
    }
  };
  const valid = async () => {
    let valid = true;
    let error = {};
    if (App.service.isNullOrEmpty(data.image_path)) {
      valid = false;
      error["image_path"] =
        "กรุณาเลือกไอคอน (ขนาดไอคอน : 50 px * 50 px นามสกุลไฟล์ png ไฟล์ไม่เกิน 1MB)";
    }
    if (App.service.isNullOrEmpty(data.name)) {
      valid = false;
      error["name"] = "กรุณาระบุข้อความ";
    }
    if (data.type == "message") {
      if (App.service.isNullOrEmpty(data.message)) {
        valid = false;
        error["message"] = "กรุณาระบุข้อความตอบกลับ";
      }
    }

    if (data.type == "uri") {
      if (App.service.isNullOrEmpty(data.message)) {
        valid = false;
        error["message"] = "กรุณาระบุลิงก์";
      } else {
        if (data.message.includes("https://")) {
          // valid = false
          // error["message"] = data.message
          // fetch(data.message, {
          //   headers: {
          //     "Content-Type": "text/html; charset=utf-8",
          //     "Sec-Fetch-Dest": "document"
          //   }
          // }).then(e => {
          //   console.log("then", e)
          // }).catch(e => {
          //   console.log("catch", e)
          // })
        } else {
          valid = false;
          error["message"] = "ลิงก์ไม่ถูกต้อง";
        }
      }
    }
    if (data.type == "postback") {
      if (valid) {
        if (data.tb_flex_messages.length < 1) {
          valid = false;
          setAlertDialog({
            open: true,
            // type: 'confirm',
            msg: "กรุณาเพิ่ม Carousel 1 รายการ",
            // Close: e => {
            //   e()

            // },
            Ok: (e) => {
              e();
            },
          });
        } else {
          if (
            [...data?.tb_flex_messages].filter(
              (e) => (e?.is_display || "").toString() == "true"
            ).length < 1
          ) {
            valid = false;
            setAlertDialog({
              open: true,
              // type: 'confirm',
              msg: "กรุณาแสดง Carousel 1 รายการ",
              // Close: e => {
              //   e()

              // },
              Ok: (e) => {
                e();
              },
            });
          }
        }
      }
    }

    if (data.type == "image") {
      if (App.service.isNullOrEmpty(data.reply_image)) {
        valid = false;
        error["reply_image"] =
          "กรุณาเลือกไอคอน (ขนาดไอคอน : 1024 px * 1024 px นามสกุลไฟล์ png ไฟล์ไม่เกิน 1MB)";
      }
    }
    if (!valid) {
      setData((e) => {
        return { ...e, isModify: true, error };
      });
    }
    return valid;
  };

  const getListNo = async () => {
    let res = await App.service.getHttp(`/quickReply/listno/3`);
    if (res.status) {
      setData((e) => {
        return { ...e, list_no: res.list_no };
      });
    }
  };
  useEffect(() => {
    if (!App.service.isNullOrEmpty(Id)) {
      getData(Id);
    } else {
      getListNo();
    }
  }, []);
  return (
    <App.PageContainer
      heading={""}
      // breadcrumbs={breadcrumbs}
    >
      <Grid
        container
        style={{
          padding: 20,
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Box
            style={{
              fontSize: "20px",

              fontWeight: 600,
            }}
          >
            {`${App.service.isNullOrEmpty(data?.id) ? "เพิ่ม" : "แก้ไข"} Quick Reply`}
            {/* เพิ่ม Quick Reply */}
          </Box>
          <Box style={{ display: "flex", gap: 10 }}>
            <Button
              className={classes.backButton}
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                if (data.isModify) {
                  setAlertDialog({
                    open: true,
                    type: "confirm",
                    msg: "มีการแก้ไขต้องการบันทึก ใช่หรือไม่?",
                    Close: (e) => {
                      e();
                      history.push("/" + listPage);
                    },
                    Ok: (e) => {
                      e();
                      doSave(() => {
                        history.push("/" + listPage);
                      });
                    },
                  });
                } else {
                  history.push("/" + listPage);
                }
              }}
              style={{
                textAlign: "center",
                width: 100,
                height: 40,
              }}
            >
              กลับ
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 100,
                height: 40,
              }}
              onClick={(e) => {
                e.preventDefault();
                doSave();
              }}
            >
              บันทึก
            </Button>
          </Box>
        </Grid>
        <Grid container item spacing={2}>
          <Grid container item {...{ xs: 8 }} spacing={4}>
            <Header.General {...{ data, onChange, classes }} />
            <Detail {...data} {...{ data, onChange, classes }} />
          </Grid>
          <Header.Preview {...{ data, onChange, classes }} />
        </Grid>
      </Grid>
    </App.PageContainer>
  );
};

export default Info;
