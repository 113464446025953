import React, { useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchStart, fetchSuccess } from "../../../redux/actions";
import service from "undefined-service-web";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import AppContext from "../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import {
  Grid,
  Box,
  FormControl,
  Button,
  TextField,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FileUploader } from "react-drag-drop-files";
import App from "components";
const useStyles = makeStyles((theme) => ({
  formControl: {
    height: "48px",
    width: "100%",
    "& .MuiInputBase-formControl": {
      height: "48px",
    },
    "& .MuiSelect-select": {
      padding: "0 10px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px, 5px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, -5px) scale(.5)",
    },
    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#f44336",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "10px",
      margin: "0",
      color: "transparent",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .Mui-focused fieldset": {
      borderWidth: "1px !important",
      borderColor: theme.palette.custom.main + " !important",
    },
    "& input": {
      fontSize: "14px",
      height: "48px",
      padding: "0px 10px",
      color: "#000",
      fontWeight: "300",
    },
    "& fieldset": { borderColor: "#e3e3e3 !important" },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336 !important",
    },
    "& .Mui-disabled.MuiInputBase-formControl": {
      background: "#f9f9f9",
      color: "#c5c5c5",
    },
    "& .FileUploader": {
      "& .dropfile": {
        width: "150px",
        height: "100px",
        minWidth: "150px",
        border: "1px solid " + theme.palette.custom.main + " !important",
        borderRadius: "10px",
        padding: "10px",
        cursor: "pointer",
      },
      "& .dropfile.error": {
        border: "1px solid red !important",
        "& .dropfile-body": {
          border: "1px dashed red !important",
        },
      },
      "& .dropfile-body": {
        border: "1px dashed  " + theme.palette.custom.main + " ",
        width: "100%",
        height: "100%",
        borderRadius: "10px",
      },
      "& .dropfile-body.image": {
        border: "none",
      },
    },
    "& .MuiSelect-root.MuiSelect-select": {
      fontSize: "14px",
      fontWeight: "300",
    },
  },
  lbl: {
    // display: 'flex',
    alignItems: "center",
    // padding: "0 10px",
    width: "calc(100% - 400px)",
    minWidth: "130px",
    maxWidth: "130px",
  },
  backButton: {
    border: "1px solid " + theme.palette.custom.main,
    backgroundColor: "#FFF",
    "& .MuiButton-label": {
      color: theme.palette.custom.main,
    },
    "&:hover": {
      border: "1px solid " + theme.palette.custom.main,
      backgroundColor: "#FFF",
    },
    boxShadow: "none",
  },
}));
const Info = () => {
  const { setAlertDialog, setAlert } = useContext(AppContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { Id } = useParams();
  const [data, setdata] = React.useState({
    id: null,
    modefy: false,
    image: {
      invalid: false,
      text: "",
      msg: "",
    },
    title: {
      invalid: false,
      text: "",
      msg: "",
    },
    detail: {
      invalid: false,
      text: "",
      msg: "",
    },
    link1: {
      invalid: false,
      text: "",
      msg: "",
    },
    linkTitle1: {
      invalid: false,
      text: "",
      msg: "",
    },
    link2: {
      invalid: false,
      text: "",
      msg: "",
    },
    linkTitle2: {
      invalid: false,
      text: "",
      msg: "",
    },
    link3: {
      invalid: false,
      text: "",
      msg: "",
    },
    linkTitle3: {
      invalid: false,
      text: "",
      msg: "",
    },
    listno: {
      invalid: false,
      text: "",
      msg: "",
    },
  });

  const onChange = (e) => {
    let name = e.target.name;
    data.modefy = true;
    let item = data[name];
    item.text = e.target.value;
    item.invalid = false;
    setdata({ ...data });
    if (name === "linkTitle2") {
      if (service.isNullOrEmpty(e.target.value)) {
        let link2 = data.link2;
        link2.invalid = false;
        setdata({ ...data, link2: link2 });
      }
    }
    if (name === "link2") {
      let linkTitle2 = data.linkTitle2;
      if (
        service.isNullOrEmpty(e.target.value) &&
        service.isNullOrEmpty(linkTitle2.text)
      ) {
        linkTitle2.invalid = false;
        setdata({ ...data, linkTitle2: linkTitle2 });
      }
    }
    if (name === "linkTitle3") {
      if (service.isNullOrEmpty(e.target.value)) {
        let link3 = data.link3;
        link3.invalid = false;
        setdata({ ...data, link3: link3 });
      }
    }
    if (name === "link3") {
      let linkTitle3 = data.linkTitle3;
      if (
        service.isNullOrEmpty(e.target.value) &&
        service.isNullOrEmpty(linkTitle3.text)
      ) {
        linkTitle3.invalid = false;
        setdata({ ...data, linkTitle3: linkTitle3 });
      }
    }
  };

  const getData = async (isCurrent, Id) => {
    dispatch(fetchStart());
    const res = await service.getHttp("/carousels/" + Id, dispatch);
    if (res.status) {
      const tbNews = res.tbNews;

      if (isCurrent) {
        setdata({
          ...data,
          modefy: false,
          image: {
            text: process.env.REACT_APP_KEY_URLAPI + tbNews.image,
          },
          title: {
            text: tbNews.title,
          },
          detail: {
            text: tbNews.detail,
          },
          link1: {
            text: tbNews.link1,
          },
          linkTitle1: {
            text: tbNews.linkTitle1,
          },
          link2: {
            text: tbNews.link2,
          },
          linkTitle2: {
            text: tbNews.linkTitle2,
          },
          link3: {
            text: tbNews.link3,
          },
          linkTitle3: {
            text: tbNews.linkTitle3,
          },
          listno: {
            text: tbNews.listno,
          },
        });
      }
    }
    dispatch(fetchSuccess());
  };
  const dosave = async (callback) => {
    if (valid()) {
      let item = {
        id: data.id,
        image: data.image.text,
        title: data.title.text,
        detail: data.detail.text,
        link1: data.link1.text,
        linkTitle1: data.linkTitle1.text,
        link2: data.link2.text,
        linkTitle2: data.linkTitle2.text,
        link3: data.link3.text,
        linkTitle3: data.linkTitle3.text,
        listno: data.listno.text,
      };
      dispatch(fetchStart());
      let _save = await service.postHttp("/carousels", item);
      if (_save.status) {
        if (_save.data.status) {
          if (callback !== undefined) {
            callback();
          } else {
            if (service.isNullOrEmpty(data.id)) {
              data.id = _save.data.id;
              data.showPassword = false;
              data.editPassword = false;
              data.modefy = false;

              setdata({ ...data });
              window.history.replaceState(
                null,
                null,
                "/carousel/" + _save.data.id
              );
              getData(true, _save.data.id);
            } else {
              data.showPassword = false;
              data.editPassword = false;
              data.modefy = false;

              setdata({ ...data });
              getData(true, data.id);
            }
            setAlert({
              open: true,
              type: "success",
              msg: "บันทึกข้อมูลสำเร็จ",
            });
          }
        } else {
          if (_save.data.isDupdate) {
            service.lodash.map([..._save.data.Dupdate], (e) => {
              if (e === "listno") {
                let listno = data.listno;
                listno.msg = "ลำดับการแสดงซ้ำกับในระบบกรุณากรอกข้อมูลใหม่";
                listno.invalid = true;

                setdata({ ...data, listno: listno });
              }
              if (e === "title") {
                let title = data.title;
                title.msg = "หัวข้อซ้ำกับในระบบกรุณากรอกข้อมูลใหม่";
                title.invalid = true;

                setdata({ ...data, title: title });
              }
            });
          } else {
            setAlertDialog({
              open: true,
              type: "alert",
              msg: "บันทึกข้อมูลสำเร็จ",
              Close: (even) => {
                even();
              },
            });
          }
        }
      } else {
        setAlertDialog({
          open: true,
          type: "alert",
          msg: "ลบข้อมูล ไม่สำเร็จ",
          Close: (even) => {
            even();
          },
        });
      }
      dispatch(fetchSuccess());
    }
  };
  const valid = () => {
    let valid = !0;
    if (service.isNullOrEmpty(data.image.text)) {
      let image = data.image;
      image.msg = "กรุณาระบุรูป";
      image.invalid = true;
      valid = !1;
      setdata({ ...data, image: image });
    }
    if (service.isNullOrEmpty(data.title.text)) {
      let title = data.title;
      title.msg = "กรุณาระบุหัวข้อ";
      title.invalid = true;
      valid = !1;
      setdata({ ...data, title: title });
    }
    if (service.isNullOrEmpty(data.linkTitle1.text)) {
      let linkTitle1 = data.linkTitle1;
      linkTitle1.msg = "กรุณาระบุชื่อ link 1";
      linkTitle1.invalid = true;
      valid = !1;
      setdata({ ...data, linkTitle1: linkTitle1 });
    }
    if (service.isNullOrEmpty(data.link1.text)) {
      let link1 = data.link1;
      link1.msg = "กรุณาระบุ link 1";
      link1.invalid = true;
      valid = !1;
      setdata({ ...data, link1: link1 });
    }

    if (
      !service.isNullOrEmpty(data.linkTitle2.text) ||
      !service.isNullOrEmpty(data.link2.text)
    ) {
      if (service.isNullOrEmpty(data.linkTitle2.text)) {
        let linkTitle2 = data.linkTitle2;
        linkTitle2.msg = "กรุณาระบุชื่อ link 2";
        linkTitle2.invalid = true;
        valid = !1;
        setdata({ ...data, linkTitle2: linkTitle2 });
      }
      if (service.isNullOrEmpty(data.link2.text)) {
        let link2 = data.link2;
        link2.msg = "กรุณาระบุ link 2";
        link2.invalid = true;
        valid = !1;
        setdata({ ...data, link2: link2 });
      }
    } else {
      let linkTitle2 = data.linkTitle2;
      let link2 = data.link2;
      linkTitle2.invalid = false;
      link2.invalid = false;
      setdata({ ...data, link2: link2, linkTitle2: linkTitle2 });
    }
    if (
      !service.isNullOrEmpty(data.linkTitle3.text) ||
      !service.isNullOrEmpty(data.link3.text)
    ) {
      if (service.isNullOrEmpty(data.linkTitle3.text)) {
        let linkTitle3 = data.linkTitle3;
        linkTitle3.msg = "กรุณาระบุชื่อ link 3";
        linkTitle3.invalid = true;
        valid = !1;
        setdata({ ...data, linkTitle3: linkTitle3 });
      }
      if (service.isNullOrEmpty(data.link3.text)) {
        let link3 = data.link3;
        link3.msg = "กรุณาระบุ link 3";
        link3.invalid = true;
        valid = !1;
        setdata({ ...data, link3: link3 });
      }
    } else {
      let linkTitle3 = data.linkTitle3;
      let link3 = data.link3;
      linkTitle3.invalid = false;
      link3.invalid = false;
      setdata({ ...data, link3: link3, linkTitle3: linkTitle3 });
    }

    if (!service.isNullOrEmpty(data.link1.text)) {
      if (!service.UrlDetect(data.link1.text)) {
        let link1 = data.link1;
        link1.msg = "link 1 ไม่ถูกต้อง";
        link1.invalid = true;
        valid = !1;
        setdata({ ...data, link1: link1 });
      }
    }
    if (!service.isNullOrEmpty(data.link2.text)) {
      if (!service.UrlDetect(data.link2.text)) {
        let link2 = data.link2;
        link2.msg = "link 2 ไม่ถูกต้อง";
        link2.invalid = true;
        valid = !1;
        setdata({ ...data, link2: link2 });
      }
    }
    if (!service.isNullOrEmpty(data.link3.text)) {
      if (!service.UrlDetect(data.link3.text)) {
        let link3 = data.link3;
        link3.msg = "link 3 ไม่ถูกต้อง";
        link3.invalid = true;
        valid = !1;
        setdata({ ...data, link3: link3 });
      }
    }

    if (service.isNullOrEmpty(data.listno.text)) {
      let listno = data.listno;
      listno.msg = "กรุณาระบุลำดับการแสดง";
      listno.invalid = true;
      valid = !1;
      setdata({ ...data, listno: listno });
    }
    return valid;
  };

  useEffect(() => {
    let isCurrent = true;
    if (!service.isNullOrEmpty(Id)) {
      data.id = Id;

      setdata({ ...data });
      getData(isCurrent, Id);
    }
    return () => {
      isCurrent = false;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer heading={""}>
      <Grid container style={{ padding: 20 }} spacing={5}>
        <Grid
          item
          container
          sm={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
          // spacing={2}
        >
          <Box
            style={{
              fontSize: "20px",

              fontWeight: 600,
            }}
          >
            <IntlMessages id="pages.manageCarousel" />
          </Box>
          <Box style={{ display: "flex", gap: 10 }}>
            <Button
              className={classes.backButton}
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                if (data.modefy) {
                  setAlertDialog({
                    open: true,
                    type: "confirm",
                    msg: "มีการแก้ไขต้องการบันทึก ใช่หรือไม่?",
                    Close: (e) => {
                      e();
                      history.push("/carousel");
                    },
                    Ok: (e) => {
                      e();
                      dosave(() => {
                        history.push("/carousel");
                      });
                    },
                  });
                } else {
                  history.push("/carousel");
                }
              }}
              style={{
                textAlign: "center",
                width: 100,
                height: 40,
              }}
              fullWidth
            >
              กลับ
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 100,
                height: 40,
              }}
              onClick={(e) => {
                e.preventDefault();
                dosave();
              }}
            >
              บันทึก
            </Button>
          </Box>
        </Grid>
        <Grid container item spacing={4}>
          <Grid container item {...{ xs: 12 }} spacing={4}>
            <Grid
              item
              {...{ xs: 12 }}
              style={{
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              รูป <span style={{ color: "red" }}>*</span>
            </Grid>
            <Grid item {...{ xs: 12 }}>
              <FileUploader
                multple={false}
                maxSize={4}
                handleChange={(e, v) => {
                  if (
                    e.type.toLowerCase().includes("png") ||
                    e.type.toLowerCase().includes("jpeg")
                  ) {
                    const _URL = window.URL || window.webkitURL;
                    const img = new Image();
                    const objectUrl = _URL.createObjectURL(e);
                    const size = e.size / 1024 / 1024;
                    img.onload = function () {
                      if (
                        this.width > 1024
                        //  ||
                        // this.height > 1024 ||
                        // this.width !== this.height
                      ) {
                        setAlertDialog({
                          open: true,
                          type: "alert",
                          msg: "ความกว้าง : ไม่เกิน 1024px (ทรงสีเหลียมผืนผ้า)",
                          Close: (even) => {
                            even();
                          },
                        });
                      } else {
                        if (size > 4) {
                          setAlertDialog({
                            open: true,
                            type: "alert",
                            msg: "ขนาดไฟล์: สูงสุด 4 MB",
                            Close: (even) => {
                              even();
                            },
                          });
                        } else {
                          service.getBase64(e, (result) => {
                            setdata({
                              ...data,
                              image: {
                                invalid: false,
                                text: result,
                                msg: "",
                              },
                            });
                          });
                        }
                      }

                      _URL.revokeObjectURL(objectUrl);
                    };
                    img.src = objectUrl;
                  } else {
                    setAlertDialog({
                      open: true,
                      type: "alert",
                      msg: "ประเภทไฟล์ไม่ถูกต้อง*ไฟล์ที่รองรับ: .JPEG, .PNG",
                      Close: (even) => {
                        even();
                      },
                    });
                  }
                }}
                name="image"
                type={["JPGE", "PNG"]}
              >
                <Box
                  style={{
                    width: "256px",
                    // height: "256px",
                    aspectRatio: 16 / 9,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: `1px ${App.service.isNullOrEmpty(data.image.text) ? "dashed" : "solid"} ${data.image.invalid ? "#f44336" : "#e3e3e3"}`,
                    borderRadius: "10px",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  {!App.service.isNullOrEmpty(data.image.text) ? (
                    <CardMedia
                      style={{
                        width: "100%",
                        height: "100%",
                        // border: "1px solid red"
                      }}
                      image={data.image.text}
                    />
                  ) : (
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M14.8225 12.6307C15.1129 12.3411 15.4999 12.1686 15.9095 12.1465C16.3191 12.1244 16.7225 12.2541 17.0425 12.5107L17.1771 12.6307L20.9425 16.3907C21.1842 16.6301 21.3252 16.9528 21.3367 17.2928C21.3483 17.6328 21.2294 17.9643 21.0045 18.2195C20.7796 18.4747 20.4657 18.6344 20.127 18.6657C19.7883 18.697 19.4504 18.5977 19.1825 18.3881L19.0571 18.2774L17.3331 16.5547V28.0001C17.3328 28.3399 17.2026 28.6668 16.9693 28.9139C16.7361 29.161 16.4172 29.3097 16.078 29.3296C15.7387 29.3496 15.4046 29.2392 15.144 29.0211C14.8834 28.8029 14.7159 28.4935 14.6758 28.1561L14.6665 28.0001V16.5547L12.9425 18.2774C12.7029 18.5186 12.3801 18.6592 12.0403 18.6703C11.7005 18.6815 11.3693 18.5624 11.1144 18.3375C10.8594 18.1125 10.7001 17.7987 10.6689 17.4601C10.6377 17.1215 10.737 16.7839 10.9465 16.5161L11.0571 16.3894L14.8225 12.6307ZM15.3331 2.66675C19.0451 2.66675 22.2131 5.00008 23.4478 8.28275C25.2925 8.78651 26.8958 9.93438 27.967 11.5184C29.0383 13.1024 29.5068 15.0177 29.2876 16.9174C29.0683 18.817 28.1759 20.5753 26.7719 21.8736C25.368 23.1719 23.5454 23.9244 21.6345 23.9947L21.3331 24.0001H19.9998V21.3334C20.7688 21.3335 21.5216 21.1119 22.1679 20.6952C22.8142 20.2784 23.3267 19.6842 23.644 18.9837C23.9612 18.2831 24.0698 17.506 23.9568 16.7453C23.8437 15.9847 23.5138 15.2727 23.0065 14.6947L22.8265 14.5027L19.0611 10.7441C18.2809 9.96538 17.2333 9.51329 16.1314 9.47985C15.0296 9.4464 13.9565 9.83413 13.1305 10.5641L12.9385 10.7441L9.17314 14.5041C8.63023 15.0465 8.2548 15.7336 8.09162 16.4834C7.92845 17.2333 7.98444 18.0143 8.25289 18.7332C8.52135 19.4521 8.99094 20.0786 9.60567 20.538C10.2204 20.9974 10.9543 21.2703 11.7198 21.3241L11.9998 21.3334V24.0001H9.33314C7.80076 24.0011 6.31481 23.4741 5.1254 22.508C3.93599 21.5418 3.11574 20.1954 2.8027 18.6953C2.48967 17.1952 2.70296 15.6331 3.40668 14.2719C4.1104 12.9106 5.26159 11.8334 6.66648 11.2214C6.69593 8.94234 7.62201 6.7666 9.24415 5.16543C10.8663 3.56426 13.0539 2.66656 15.3331 2.66675Z"
                            fill={data.image.invalid ? "#f44336" : "#B8B9BA"}
                          />
                        </svg>
                      </Box>
                      <Box
                        style={{
                          fontSize: "12px",
                          color: data.image.invalid ? "#f44336" : "#B8B9BA",
                        }}
                      >
                        เลือกรูปภาพ
                      </Box>
                    </Box>
                  )}
                </Box>
              </FileUploader>
            </Grid>
            <Grid
              item
              {...{ xs: 12 }}
              style={{
                fontSize: "12px",
                fontWeight: "300",
                color: data.image.invalid ? "#f44336" : "#B8B9BA",
              }}
            >
              {
                "ขนาดไฟล์: สูงสุด 4 MB ความกว้าง : ไม่เกิน 1024px (ทรงสีเหลียมผืนผ้า)  นามสกุลไฟล์ .png "
              }
            </Grid>
          </Grid>

          <Grid container item {...{ xs: 12 }} spacing={2}>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                หัวข้อ <span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-title"
                    name="title"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.title.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    placeholder="หัวข้อ"
                    error={data.title.invalid}
                    helperText={data.title.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item {...{ xs: 12 }} spacing={2}>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                <Box className={classes.lbl}>รายละเอียด</Box>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-detail"
                    name="detail"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.detail.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    placeholder="รายละเอียด"
                    error={data.detail.invalid}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item {...{ xs: 12 }} spacing={2}>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                <Box className={classes.lbl}>
                  ชื่อลิงก์ 1 <span style={{ color: "red" }}>*</span>
                </Box>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-linkTitle1"
                    name="linkTitle1"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.linkTitle1.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 40,
                    }}
                    placeholder="ระบุชื่อลิงก์ 1"
                    error={data.linkTitle1.invalid}
                    helperText={data.linkTitle1.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                ลิงก์ 1<span style={{ color: "red" }}>*</span>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-link1"
                    name="link1"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.link1.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    placeholder="ระบุลิงก์ 1"
                    error={data.link1.invalid}
                    helperText={data.link1.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item {...{ xs: 12 }} spacing={2}>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                <Box className={classes.lbl}>ชื่อลิงก์ 2</Box>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-linkTitle2"
                    name="linkTitle2"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.linkTitle2.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 40,
                    }}
                    placeholder="ระบุชื่อลิงก์ 2"
                    error={data.linkTitle2.invalid}
                    helperText={data.linkTitle2.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                ลิงก์ 2
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-link2"
                    name="link2"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.link2.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    placeholder="ระบุลิงก์ 2"
                    error={data.link2.invalid}
                    helperText={data.link2.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item {...{ xs: 12 }} spacing={2}>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                <Box className={classes.lbl}>ชื่อลิงก์ 3</Box>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-linkTitle3"
                    name="linkTitle3"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.linkTitle3.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 40,
                    }}
                    placeholder="ระบุชื่อลิงก์ 3"
                    error={data.linkTitle3.invalid}
                    helperText={data.linkTitle3.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid
                item
                {...{ xs: 12 }}
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                ลิงก์ 3
              </Grid>{" "}
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-link3"
                    name="link3"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.link3.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                    }}
                    placeholder="ระบุลิงก์ 3"
                    error={data.link3.invalid}
                    helperText={data.link3.msg}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item {...{ xs: 12 }} spacing={2}>
            <Grid container item {...{ xs: 12, sm: 12, md: 6 }} spacing={2}>
              <Grid item {...{ xs: 12 }}>
                <Box
                  className={classes.lbl}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  ลำดับการแสดง <span style={{ color: "red" }}>*</span>
                </Box>
              </Grid>
              <Grid item {...{ xs: 12 }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="input-listno"
                    name="listno"
                    disabled={false}
                    type={"text"}
                    onChange={onChange}
                    value={data.listno.text}
                    variant="outlined"
                    inputProps={{
                      maxLength: 250,
                      style: { textAlign: "right" },
                    }}
                    placeholder="ลำดับการแสดง"
                    error={data.listno.invalid}
                    helperText={data.listno.msg}
                    onKeyDown={(e) => {
                      if (
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowRight" &&
                        e.key !== "ArrowLeft"
                      )
                        if (!"0123456789".includes(e.key)) {
                          e.preventDefault();
                        }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Info;
