import columns from "./columns";
import Head from "./head";
import setup from "./setup";
const Default = {
  columns,
  Head,
  setup,
};

export default Default;
