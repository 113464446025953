import { MaterialReactTableUC } from './MaterialTableUC'
import service from 'undefined-service-web'
import PageContainer from '../@jumbo/components/PageComponents/layouts/PageContainer'
import AppContext from '../@jumbo/components/contextProvider/AppContextProvider/AppContext'

import { useDispatch } from 'react-redux'
import { fetchStart, fetchSuccess } from '../redux/actions'
const App = {
    PageContainer,
    AppContext,
    MaterialReactTableUC,
    service,
    Redux: {
        useDispatch,
        fetchStart,
        fetchSuccess
    }
}

export default App