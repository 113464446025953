import SystemUC from "./system";
import MessageUC from "./message";
import ImageUC from "./image";
import UriUC from "./uri";
import PostbackUC from "./postback";

const detail = ({ type, classes, onChange, data }) => {
  switch (type) {
    case "system":
      console.log(data);
      if (data.extra_image) {
        return <ImageUC {...{ classes, onChange, data }} />;
      } else if (data.extra_url) {
        return <UriUC {...{ classes, onChange, data }} />;
      } else if (data.extra_carousel) {
        return <PostbackUC {...{ classes, onChange, data }} />;
      }

      return null;

    case "message":
      return <MessageUC {...{ classes, onChange, data }} />;

    case "image":
      return <ImageUC {...{ classes, onChange, data }} />;

    case "uri":
      return <UriUC {...{ classes, onChange, data }} />;
    case "postback":
      return <PostbackUC {...{ classes, onChange, data }} />;

    default:
      return null;
  }
};
export default detail;
