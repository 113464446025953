import React, { useMemo } from "react";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
const type = {
  system: "System",
  message: "Message",
  image: "Image",
  uri: "URL Link",
  postback: "Carousel",
};
const Columns = ({
  info = "",
  doDelete = () => {},
  setAlertDialog = () => {},
}) => {
  return useMemo(() => [
    {
      header: "ข้อความ",
      accessorKey: "name",
    },
    {
      header: "ประเภท",
      accessorKey: "type",
      Cell: ({ row }) => {
        return type[row.original.type];
      },
    },
    {
      header: "สถานะ",
      accessorKey: "isDisplay",
      Cell: ({ row }) => {
        return row.original.is_display ? (
          <Box style={{ color: "#76CA66" }}>แสดง</Box>
        ) : (
          <Box style={{ color: "#F16666" }}> ไม่แสดง</Box>
        );
      },
    },

    {
      header: "จัดการ",
      accessorKey: "edit",
      Cell: ({ row }) => {
        console.log(row);
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box style={{ padding: "0 5px" }}>
              <NavLink
                to={`/${info}/${row.original.id}`}
                style={{ color: "#000" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.71 7.04006C21.1 6.65006 21.1 6.00006 20.71 5.63006L18.37 3.29006C18 2.90006 17.35 2.90006 16.96 3.29006L15.12 5.12006L18.87 8.87006M3 17.2501V21.0001H6.75L17.81 9.93006L14.06 6.18006L3 17.2501Z"
                    fill="#0066FF"
                  />
                </svg>
              </NavLink>
            </Box>
            <Box style={{ padding: "0 5px" }}>
              <Box
                style={{
                  opacity: row.original.type == "system" ? 0.5 : 1,
                  cursor: row.original.type == "system" ? "" : "pointer",
                }}
                onClick={() => {
                  if (row.original.type != "system") {
                    setAlertDialog({
                      open: true,
                      type: "confirm",
                      msg: "ต้องการลบข้อมูล ใช่หรือไม่?",
                      Close: (even) => {
                        even();
                      },
                      Ok: (even) => {
                        even();
                        doDelete(row.original.id);
                      },
                    });
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                    fill="#0066FF"
                  />
                </svg>
              </Box>
              {/* <DeleteOutline
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    // console.log(e)
                                    // setAlertDialog({
                                    //     open: true,
                                    //     type: 'confirm',
                                    //     msg: 'ต้องการลบข้อมูล ใช่หรือไม่?',
                                    //     Close: even => {
                                    //         even()
                                    //     },
                                    //     Ok: even => {
                                    //         even()
                                    //         doDelete(e.id)
                                    //     },
                                    // })
                                }}
                            /> */}
            </Box>
          </Box>
        );
      },
      minSize: 50, //min size enforced during resizing
      maxSize: 50, //max size enforced during resizing
      size: 50, //medium column
      muiTableHeadCellProps: {
        align: "right",
        padding: "5px",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
    },
  ]);
};

export default Columns;
