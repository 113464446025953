import React from "react";
import { Photo, PostAdd, Assignment, Reply } from "@material-ui/icons";
import IntlMessages from "../@jumbo/utils/IntlMessages";
///config menu
export const sidebarNavs = [
  {
    name: <IntlMessages id={"pages.manageBroadcast"} />,
    type: "item",
    icon: <Photo />,
    link: "/broadcast",
    childrenlink: ["/broadcastinfo"],
  },
  {
    name: <IntlMessages id={"pages.manageQuickReply"} />,
    type: "collapse",
    icon: <Reply />,
    // link: '/broadcast',
    // childrenlink: ['/broadcastinfo'],
    children: [
      {
        name: <IntlMessages id={"pages.airQuality"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/air-quality",
        childrenlink: ["/air-quality-info"],
      },
      {
        name: <IntlMessages id={"pages.safetyZone"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/safety-zone",
        childrenlink: ["safety-zone-info"],
      },
      {
        name: <IntlMessages id={"pages.forecast"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/forecast",
        childrenlink: ["forecast-info"],
      },
      {
        name: <IntlMessages id={"pages.hotspot"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/hotspot",
        childrenlink: ["hotspot-info"],
      },
    ],
  },
  {
    name: <IntlMessages id={"pages.manageCarousel"} />,
    type: "item",
    icon: <Assignment />,
    link: "/carousel",
    childrenlink: ["/carouselinfo"],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page",
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page",
      },
    ],
  },
];
