import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types"; // Import PropTypes
import App from "components";  // Assuming this is a custom App component

/**
 * Message Component
 * 
 * @param {object} props - The properties passed to the component.
 * @returns {JSX.Element} A JSX element to render.
 */
const Message = (props) => {
    const { data } = props;

    return (
        <Box
            style={{
                display: "flex",
                gap: "10px",
                position: "relative",
                WebkitUserSelect: "none", /* Safari */
                MsUserSelect: "none", /* IE 10 and IE 11 */
                userSelect: "none",
            }}
        >
            <Box
                style={{
                    position: "absolute",
                    right: "-4px",
                    top: "-5px",
                }}
            >
                <svg
                    width="10"
                    height="9"
                    viewBox="0 0 10 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.29584 -0.00437689C9.70562 6.76272 5.6096 8.28642 3.51037 8.20239L0.0231829 1.75352C0.988771 2.77822 7.16692 1.3642 9.29584 -0.00437689Z"
                        fill="#C9F1CB"
                    />
                </svg>
            </Box>
            <Box
                style={{
                    fontSize: "10px",
                    display: "flex",
                    alignItems: "end",
                }}
            >
                {`${App.service.moment().format("HH:MM")} น.`}
            </Box>
            <Box
                style={{
                    backgroundColor: "#C9F1CB",
                    minHeight: "33px",
                    height: "auto",
                    maxWidth: "150px",
                    padding: "5px 10px 5px 10px",
                    borderRadius: "10px",
                    fontSize: "12px",
                    wordWrap: "break-word",
                }}
            >
                {data?.name}
            </Box>
        </Box>
    );
};

// Default props
Message.defaultProps = {
    data: {}, // Default to an empty object if no data is provided
};

// Prop types validation
Message.propTypes = {
    data: PropTypes.object.isRequired, // data should be an object
};

export default Message;
