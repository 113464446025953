import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import App from "components";
import React, { useEffect, useMemo, useState, useContext } from "react";

import DialogUC from "./dialog";
import { DeleteOutline, Edit } from "@material-ui/icons";
// const useStyles = makeStyles((theme) => ({
//   "& .MuiTableRow-root": {
//     display: "none",
//   },
// }));
const Columns = (setOpen, doDelete) => {
  const { setAlertDialog, setAlert } = useContext(App.AppContext);
  return useMemo(() => [
    {
      header: "หัวข้อ",
      accessorKey: "title",
    },
    {
      header: "รายละเอียด",
      accessorKey: "detail",
    },
    {
      header: "สถานะ",
      accessorKey: "isDisplay",
      maxSize: 50,
      Cell: ({ row }) => {
        return (row?.original?.is_display).toString() == "true" ? (
          <Box style={{ color: "#76CA66" }}>แสดง</Box>
        ) : (
          <Box style={{ color: "#F16666" }}> ไม่แสดง</Box>
        );
      },
    },

    {
      header: "จัดการ",
      accessorKey: "edit",

      Cell: (e, r) => {
        console.log(e);
        return (
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Box
              style={{ padding: "0 5px", color: "#000000", cursor: "pointer" }}
              onClick={() => setOpen({ is_open: true, index: e.row.index })}
            >
              <Edit />
            </Box>
            <Box style={{ padding: "0 5px" }}>
              <DeleteOutline
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // console.log(e)
                  setAlertDialog({
                    open: true,
                    type: "confirm",
                    msg: "ต้องการลบข้อมูล ใช่หรือไม่?",
                    Close: (even) => {
                      even();
                    },
                    Ok: (even) => {
                      even();
                      doDelete(e.row.original.id, e.row.index);
                    },
                  });
                }}
              />
            </Box>
          </Box>
        );
      },
      maxSize: 80,
      //   cellStyle: { maxWidth: "80px", width: "80px" },
    },
  ]);
};

const PostbackUC = ({ data, onChange, classes }) => {
  //   const tableCss = useStyles();
  const [open, setOpen] = useState({
    is_open: false,
    index: null,
  });

  const doDelete = (id, index) => {
    // console.log(index)
    let _table_temp = [];
    let _table = data.tb_flex_messages;
    _table = _table.filter((e, i) => i != index);
    _table.map((e, i) => {
      _table_temp.push({
        ...e,
        list_no: i,
      });
    });
    onChange({
      name: "tb_flex_messages",
      value: _table_temp,
    });
    if (!App.service.isNullOrEmpty(id)) {
      setTimeout(() => {
        onChange({
          name: "delete",
          value: [...(data?.delete || []), id],
        });
      }, 500);
    }
  };
  const columns = Columns(setOpen, doDelete);
  const onDragEnd = (list) => {
    console.log(list);
    let _table_temp = [];
    list.map((e, i) => {
      _table_temp.push({
        ...e,
        list_no: i,
      });
    });
    onChange({
      name: "tb_flex_messages",
      value: _table_temp,
    });
  };
  return (
    <Grid container item {...{ xs: 12 }} spacing={2}>
      <Grid
        item
        {...{ xs: 12 }}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>รายการ Carousel</Box>
        <Button
          variant="contained"
          color="primary"
          style={{
            width: 100,
            height: 40,
          }}
          onClick={(e) =>
            setOpen((e) => {
              return {
                is_open: true,
                index: null,
              };
            })
          }
        >
          เพิ่ม
        </Button>
      </Grid>
      <Grid item {...{ xs: 12 }}>
        <App.MaterialReactTableUC
          columns={columns}
          list={[...(data?.tb_flex_messages || [])]}
          onDragEnd={onDragEnd}
        />
      </Grid>
      <DialogUC
        {...{
          open,
          setOpen,
          classes,
          Table: data,
          onChangeTable: onChange,
        }}
      />
    </Grid>
  );
};

export default PostbackUC;
