import React from "react";
import { Box, CardMedia } from "@material-ui/core";
import PropTypes from 'prop-types'
import App from "components";
import Message from "./Message";
/**
 * TypePostBack Component
 * 
 * @param {object} props - The properties passed to the component.
 * @returns {JSX.Element} A JSX element to render.
 */
const TypePostBack = (props) => {
    const { data } = props
    return (
        <Box
            style={{
                width: "308px",
                top: "120px",
                padding: "10px 20px 0 20px",
                overflowX: "hidden",
            }}
        >
            <Box
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    marginBottom: 5,
                }}
            >
                {data?.name && <Message {...{ data }} />}
            </Box>
            <Box
                style={{
                    width: "308px",
                    // border: "1px solid red",
                    height: "350px",
                }}
            >
                {[...data?.tb_flex_messages].filter(
                    (e) => e.is_display.toString() == "true"
                ).length > 0 && (
                        <Box
                            style={{
                                justifyContent: "flex-start",
                                width: "100%",
                                gap: "10px",
                            }}
                        >
                            <Box>
                                <CardMedia
                                    style={{
                                        width: "30px",
                                        aspectRatio: 1,
                                        borderRadius: "50px",
                                    }}
                                    image={
                                        "https://profile.line-scdn.net/0h61W4TRlbaR5qTXqhcpkWSVYIZ3MdY29WEiMjf0tLZS0TLX5AASghL0hMYi0VeS1OX3tzehtJN3tH"
                                    }
                                ></CardMedia>
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    // marginTop: "10px",
                                    gap: 10,
                                }}
                            >
                                <Box
                                    style={{
                                        width: "100%",
                                        overflowX: "hidden",
                                    }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            // flexDirection: "row",
                                            gap: 10,
                                            width: "308px",
                                            overflowX: "hidden",
                                        }}
                                    >
                                        {[...data?.tb_flex_messages]
                                            .filter((e) => e.is_display.toString() == "true")
                                            .map((e) => {
                                                return (
                                                    <Box
                                                        style={{
                                                            marginTop: "10px",
                                                            marginBottom: "10px",
                                                            justifyContent: "flex-start",
                                                            width: "220px",
                                                            // gap: 20,
                                                            borderRadius: "10px",
                                                            // overflow: "hidden",
                                                            backgroundColor: "#FFFFFF",
                                                        }}
                                                    >
                                                        <CardMedia
                                                            style={{
                                                                width: "220px",
                                                                aspectRatio: 4 / 2,
                                                                objectFit: "cover",
                                                                borderRadius: "10px 10px 0px 0px",
                                                            }}
                                                            image={e?.image_path}
                                                        ></CardMedia>
                                                        <Box
                                                            style={{
                                                                padding: "5px 10px 5px 10px",
                                                                fontSize: "13px",
                                                                fontWeight: "600",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {e.title}
                                                        </Box>
                                                        <Box
                                                            style={{
                                                                padding: "5px 10px 5px 10px",
                                                                fontSize: "13px",
                                                                fontWeight: "400",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >
                                                            {e.detail}
                                                        </Box>
                                                        {e?.is_display1 == true && (
                                                            <Box
                                                                style={{
                                                                    height: 40,
                                                                    textAlign: "center",
                                                                    color: "#5A7ED4",
                                                                    fontSize: "13px",
                                                                }}
                                                            >
                                                                {e.link_title1}
                                                            </Box>
                                                        )}
                                                        {e?.is_display2 == true && (
                                                            <Box
                                                                style={{
                                                                    height: 40,
                                                                    textAlign: "center",
                                                                    color: "#5A7ED4",
                                                                    fontSize: "13px",
                                                                }}
                                                            >
                                                                {e.link_title2}
                                                            </Box>
                                                        )}
                                                        {e?.is_display3 == true && (
                                                            <Box
                                                                style={{
                                                                    height: 40,
                                                                    textAlign: "center",
                                                                    color: "#5A7ED4",
                                                                    fontSize: "13px",
                                                                }}
                                                            >
                                                                {e.link_title3}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                );
                                            })}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                {[...data?.tb_flex_messages].filter(
                    (e) => e.is_display.toString() == "true"
                ).length > 0 && (
                        <Box
                            style={{
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end",
                                paddingRight:
                                    [...data?.tb_flex_messages].length == 1 ? "50px" : "0px",
                            }}
                        >
                            {`${App.service.moment().format("HH:MM")} น.`}
                        </Box>
                    )}
            </Box>
        </Box>
    );
};
TypePostBack.defaultProps = {
    data: {} // Default to an empty object if no data is provided
};

// Prop Types Validation
TypePostBack.propTypes = {
    data: PropTypes.object.isRequired, // data should be an object
};


export default TypePostBack