import header from './header'
import detail from './detail'

import styles from './styles'
export const Header = header
export const Detail = detail
export const useStyles = styles
const Default = {
    Header,
    Detail,
    useStyles
}
export default Default